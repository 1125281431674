<template>
  <section class="cmt-row clearfix">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div
            class="cmt-col-bgcolor-yes cmt-bg cmt-bgcolor-grey spacing-9 border-right"
          >
            <div class="cmt-col-wrapper-bg-layer cmt-bg-layer"></div>
            <div class="section-title with-desc clearfix">
              <div class="title-header">
                <h2 class="title">Get in Touch</h2>
              </div>
            </div>
            <form
              id="contact-form2"
              @submit="sendmail2()"
              action="javascript:void(0)"
              class="contact-form"
              method="POST"
            >
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      name="name"
                      id="name"
                      type="text"
                      value=""
                      placeholder="Your Name"
                      required="required"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      name="email"
                      type="text"
                      id="email"
                      value=""
                      placeholder="Your Email"
                      required="required"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      name="phone"
                      type="text"
                      id="phone"
                      value=""
                      placeholder="Phone Number"
                      required="required"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      name="subject"
                      type="text"
                      id="subject"
                      value=""
                      placeholder="Subject"
                      required="required"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <textarea
                      name="message"
                      rows="3"
                      id="message"
                      placeholder="Message"
                      required="required"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <button
                    class="submit cmt-btn cmt-btn-size-md cmt-btn-shape-square cmt-btn-style-fill cmt-btn-color-skincolor"
                    type="submit"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-4">
          <div
            class="cmt-col-bgcolor-yes cmt-bg cmt-bgcolor-grey spacing-9 border-left"
          >
            <div class="cmt-col-wrapper-bg-layer cmt-bg-layer"></div>
            <div class="layer-content">
              <div class="mb-15 pb-15 border-bottom">
                <h5>Location</h5>
                <div class="d-flex">
                  <div
                    class="cmt-icon cmt-icon_element-onlytxt cmt-icon_element-color-skincolor cmt-icon_element-size-sm mr-2 mb-0"
                  >
                    <i class="flaticon flaticon-world"></i>
                  </div>
                  <div>
                    {{ contactData.company_address }}
                  </div>
                </div>
              </div>
              <div class="mb-15 pb-15 border-bottom">
                <h5>Phone</h5>
                <div class="d-flex">
                  <div
                    class="cmt-icon cmt-icon_element-onlytxt cmt-icon_element-color-skincolor cmt-icon_element-size-sm mr-2 mb-0"
                  >
                    <i class="flaticon flaticon-call"></i>
                  </div>
                  <div>{{ contactData.company_contact_no_1 }} <br/> {{ contactData.company_contact_no_2 }}</div>
                </div>
              </div>
              <div class="mb-15 pb-15 border-bottom">
                <h5>Email</h5>
                <div class="d-flex">
                  <div
                    class="cmt-icon cmt-icon_element-onlytxt cmt-icon_element-color-skincolor cmt-icon_element-size-sm mr-2 mb-0"
                  >
                    <i class="flaticon flaticon-message"></i>
                  </div>
                  <div> 
                    <a href="javascript:void(0)">{{ contactData.company_info_mailid }}</a><br/>
                    <a href="javascript:void(0)">{{ contactData.company_supply_mailid }}</a>
                  </div>
                </div>
              </div>
              <h5>Get Social</h5>
              <ul class="social-icons circle social-hover mt-15">
                <li class="social-facebook">
                  <a
                    class="tooltip-top"
                    target="_blank"
                    href="#"
                    data-tooltip="Facebook"
                    ><i class="fa fa-facebook" aria-hidden="true"></i
                  ></a>
                </li>
                <li class="social-twitter">
                  <a
                    class="tooltip-top"
                    target="_blank"
                    href="#"
                    data-tooltip="Twitter"
                    ><i class="fa fa-twitter" aria-hidden="true"></i
                  ></a>
                </li>
                <!-- <li class="social-gplus">
                  <a
                    class=" tooltip-top"
                    target="_blank"
                    href="#"
                    data-tooltip="Google+"
                    ><i class="fa fa-youtube" aria-hidden="true"></i
                  ></a>
                </li> -->
                <!-- <li class="social-linkedin">
                  <a
                    class=" tooltip-top"
                    target="_blank"
                    href="#"
                    data-tooltip="LinkedIn"
                    ><i class="fa fa-linkedin" aria-hidden="true"></i
                  ></a>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div id="google_map" class="google_map">
    <div class="map_container">
      <div id="map">
        <iframe
          :src="contactData.location_map"
          height="450"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { Email } from "../assets/js/smtp.js";
import swal from "sweetalert2";
window.Swal = swal;
import data from "../Data/data.json";
export default {
  data() {
    return {
      contactData: "",
    };
  },
  created() {
    this.contactData = data.company_detailes;
  },
  methods: {
    sendmail2() {
      var name = $("#name").val();
      var email = $("#email").val();
      var phone = $("#phone").val();
      var subject = $("#subject").val();
      var message = $("#message").val();
      var Body =
        "First Name: " +
        name +
        "<br>Address: " +
        email +
        "<br>Phone: " +
        phone +
        "<br>Subject: " +
        subject +
        "<br>Message: " +
        message;

      Email.send({
        SecureToken: "b6183983-9783-4396-8d62-4086d1a63a39",
        To: "operation@greenearthmarine.com",
        // To: "john09082017@gmail.com",
        From: "greenearthmarinesolutions@gmail.com",
        Subject: "New message on contact from " + name,
        Body: Body,
      }).then((message) => {
        if (message == "OK") {
          new swal(
            "Thank You For Message Us!",
            "We will contact you as soon as possible!",
            "success"
          );
          document.getElementById("contact-form2").reset();
        } else {
          console.error(message);
          new swal("Oops!", "Something went wrong, please try again!", "error");
          document.getElementById("contact-form2").reset();
        }
      });
    },
  },
};
</script>
