<template>
  <div class="cmt-page-title-row">
    <div class="cmt-page-title-row-inner">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12">
            <div class="page-title-heading">
              <h2 class="title">{{ routeName }}</h2>
            </div>
            <div class="breadcrumb-wrapper">
              <span>
                <router-link title="Homepage" to="/">Home</router-link>
              </span>
              <span>{{ routeName }}</span> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        routeName: ''
    };
  },
  mounted() {
    this.routeName = this.$route.name;
    // console.log("this is router", this.routeName)
  },
};
</script>
